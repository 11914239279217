import { keymap } from "prosemirror-keymap"
import { Extension } from "tiptap"
import {
  redo,
  undo,
  yCursorPlugin,
  ySyncPlugin,
  yUndoPlugin,
} from "y-prosemirror"
import { WebsocketProvider } from "y-websocket"
import * as Y from "yjs"

export default class RealtimeExtension extends Extension {
  constructor(obj: { ydoc: Y.Doc; websocketProvider: WebsocketProvider }) {
    super(obj)
  }

  get name() {
    return "realtime"
  }

  get defaultOptions() {
    return {
      ydoc: null,
      websocketProvider: null,
    }
  }

  get plugins() {
    const type = this.options.ydoc.getXmlFragment("prosemirror")
    return [
      ySyncPlugin(type),
      yCursorPlugin(this.options.websocketProvider.awareness),
      yUndoPlugin(),
      keymap({
        "Mod-z": undo,
        "Mod-y": redo,
        "Mod-Shift-z": redo,
      }),
    ]
  }

  commands() {
    return {
      undo: () => undo,
      redo: () => redo,
    }
  }
}
